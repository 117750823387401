import { Vue, Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BButton, BForm, BFormInput, BFormGroup, BLink } from "bootstrap-vue";
import { BCard, BCardTitle, BCardText } from "bootstrap-vue";
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend } from "bootstrap-vue";
import AltLogo from "@/core/components/alt-logo";

@Component({
    components: {
        BCard,
        BCardTitle,
        BLink,
        BCardText,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        AltLogo,
    },
})
export default class ForgotPassword extends Vue {
    private resources = {
        LabelTitleText: "ВОССТАНОВЛЕНИЕ ПАРОЛЯ",
        LabelSubtitle1Text: "Восстановление пароля",
        LabelSubtitle2Text: "Пожалуйста, введите электронную почту:",
        TextBoxEmailLabel: "Почта",
        LinkLoginText: "Вход на сайт",
        ButtonText: "Восстановить",
        ValidatorId: "forgotPasswordForm",
    };

    private email: string = "";

    private async sendEmail(): Promise<void> {
        try {
            const valid = await (this.$refs[this.resources.ValidatorId] as any).validate();
            if (!valid) {
                return;
            }

            this.$alt.loader.show();

            const service = this.$alt.system.usecase.createAuthCompanyUseCase();
            await service.sendResetPasswordEmail(this.email);

            this.$alt.toast.success("Письмо отправлено.", "Восстановление пароля");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }
}
